import React, { useMemo } from 'react'
import { Navigate } from 'react-router-dom'

import { AuthStatus, useAuthContext } from '@/providers/AuthProvider'

export interface PrivateRouteProps {
    children?: React.ReactNode
    targetStatus: AuthStatus[]
    redirectStatus?: AuthStatus[]
    redirectPath?: string
    defaultElement?: React.ReactNode
}

export function PrivateRoute({
    children,
    targetStatus = [AuthStatus.SignedIn],
    redirectStatus,
    redirectPath = '/sign-in',
    defaultElement,
}: PrivateRouteProps) {

    const { status } = useAuthContext()

    const statusRedirects = useMemo(
        () => redirectStatus ?? targetStatus.map(s => {
            switch (s) {
                case AuthStatus.SignedIn:
                    return AuthStatus.SignedOut
                case AuthStatus.SignedOut:
                    return AuthStatus.SignedIn
                default:
                    return null
            }
        }).filter(s => s !== null) as AuthStatus[],
        [redirectStatus, targetStatus]
    )

    if (targetStatus.includes(status)) {
        return children
    } else if (statusRedirects.includes(status)) {
        return <Navigate to={redirectPath} replace />
    }

    return defaultElement ?? <div>loading private route ({status})...</div>

}