import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import { db } from '@/firebase'
import { ref, push, onValue, onChildAdded, onChildChanged, serverTimestamp, DataSnapshot } from 'firebase/database'

import { ConversationStore, ConversationState, Message } from './types'
import { restoreStateFromThreadHistory } from './event-sourcing'
import { Thread } from '@/stores/threads/types'

const initialState: ConversationState = {
    isTyping: true,
    messages: [],
    nextExpectedEncoding: 'text',
    purchaseDetails: {},
}

export const useConversationStore = create(
    immer<ConversationStore>((set, get) => ({

        ...initialState,

        subscribeConversation: (userId, threadId) => {

            let unsubscribeConversation = get().unsubscribeConversation

            if (unsubscribeConversation) {
                unsubscribeConversation()
            }

            const threadRef = ref(db, `threads/${userId}/${threadId}`)

            const unsubValue = onValue(threadRef, (snapshot) => {

                const thread: Thread | null = snapshot.val()

                if (!thread) {
                    return
                }

                set(state => {
                    state.thread = {
                        ...thread,
                        id: snapshot.key!,
                    }
                })

            }, (e) => console.error(e))

            const messagesRef = ref(db, `messages/${threadId}`)

            const unsubChildAdded = onChildAdded(messagesRef, (snapshot: DataSnapshot) => {

                const message: Message | null = snapshot.val()

                if (!message) {
                    return
                }

                set(state => {

                    state.messages.push({
                        ...message,
                        id: snapshot.key!,
                    })

                    restoreStateFromThreadHistory(state)

                })



            }, (e) => console.error(e))

            const unsubChildChanged = onChildChanged(messagesRef, (snapshot: DataSnapshot) => {

                const message: Message | null = snapshot.val()

                if (!message) {
                    return
                }

                set(state => {

                    const index = state.messages.findIndex(m => m.id === snapshot.key!)

                    if (index === -1) {
                        return
                    }

                    state.messages[index] = {
                        ...message,
                        id: snapshot.key!,
                    }

                    restoreStateFromThreadHistory(state)

                })

            }, (e) => console.error(e))

            unsubscribeConversation = () => {
                unsubValue()
                unsubChildAdded()
                unsubChildChanged()

                set({ ...initialState })
            }

            set(state => { state.unsubscribeConversation = unsubscribeConversation })

            return unsubscribeConversation

        },

        sendMessage: async (userId, message) => {

            const {
                thread,
                nextExpectedEncoding,
                replyToMessageId,
            } = get()


            if (!thread) {
                throw new Error('No thread selected')
            }

            if (replyToMessageId) {

                switch (nextExpectedEncoding) {

                    case 'selected_choices':

                        switch (message.encoding) {
                            case 'text':
                                message = {
                                    choices: [message.body!],
                                    encoding: 'selected_choices',
                                    reply_to_message_id: replyToMessageId,
                                }

                                break;

                        }

                        break

                }

            }

            const messagesRef = await push(ref(db, `messages/${thread.id}`), {
                ...message,
                user_id: userId,
                sender: 'user',
                created_at: serverTimestamp(),
            })

            return messagesRef.key!

        }

    }))
)