import React, { createContext, useEffect, useState, useContext } from 'react'

import * as Sentry from '@sentry/browser'

import {
    GoogleAuthProvider,
    onAuthStateChanged,
    signInWithRedirect,
    signOut,
    User,
    UserCredential,
    indexedDBLocalPersistence,
    getRedirectResult,
} from 'firebase/auth'

import { useThreadsStore } from '@/stores/threads/store'
import { useConversationStore } from '@/stores/conversation/store'

import { auth } from '@/firebase'

const providers = {
    google: new GoogleAuthProvider()
}

providers.google.addScope('https://www.googleapis.com/auth/userinfo.email')
providers.google.addScope('https://www.googleapis.com/auth/userinfo.profile')

export enum AuthStatus {
    Unknown = 'unknown',
    SigningIn = 'signing-in',
    SignedIn = 'signed-in',
    SigningOut = 'signing-out',
    SignedOut = 'signed-out',
}

export interface AuthContextValue {
    user: User | null
    status: AuthStatus
    signInWithGoogle: () => Promise<UserCredential>
    signOutUser: () => Promise<void>
}

export const AuthContext = createContext<AuthContextValue | null>(null)

export const useAuthContext = () => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuthContext must be used within an AuthProvider')
    }
    return context
}

export interface AuthProviderProps {
    children: React.ReactNode
}

export function AuthProvider({ children }: AuthProviderProps) {

    const [user, setUser] = useState<User | null>(null)
    const [status, setStatus] = useState(AuthStatus.Unknown)

    const unsubscribeThreads = useThreadsStore(state => state.unsubscribeThreads)
    const unsubscribeConversation = useConversationStore(state => state.unsubscribeConversation)

    const signInWithGoogle = async () => {

        setStatus(AuthStatus.SigningIn)

        await auth.setPersistence(indexedDBLocalPersistence)

        await signInWithRedirect(auth, providers.google)

        const userCredential = await getRedirectResult(auth)

        if (userCredential) {

            Sentry.setUser({
                email: userCredential.user.email ?? undefined,
                id: userCredential.user.uid,
                username: userCredential.user.displayName ?? undefined,
            })

            return userCredential

        }

        throw new Error('No user credential')

    }

    const signOutUser = async () => {

        setStatus(AuthStatus.SigningOut)
        
        await signOut(auth)
        
        Sentry.setUser(null)

        if (unsubscribeThreads) {
            unsubscribeThreads()
        }
        
        if (unsubscribeConversation) {
            unsubscribeConversation()
        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setStatus(currentUser ? AuthStatus.SignedIn : AuthStatus.SignedOut)
            setUser(currentUser)
        })

        return () => {
            unsubscribe()
        }
    }, [])

    return <AuthContext.Provider value={{
        user,
        status,
        signInWithGoogle,
        signOutUser,
    }}>{children}</AuthContext.Provider>
}