import { ConversationState, MessageSender } from './types'

interface ReplyToAnswer {
    value: string[]
    sender: MessageSender
}

export function restoreStateFromThreadHistory(state: ConversationState) {

    const replyToAnswers = new Map<string, ReplyToAnswer>()

    const lastMessageIndex = state.messages.length - 1

    if (lastMessageIndex < 0) {
        state.isTyping = true
        return
    }

    for (let index = lastMessageIndex; index >= 0; index--) {

        const message = state.messages[index]

        if (lastMessageIndex === index) {
            state.isTyping = message.encoding === 'typing'
        }

        switch (message.encoding) {

            case 'product_name':

                state.purchaseDetails.product_name = message.product_name!
                state.purchaseDetails.product_category = message.product_category!

                break

            case 'selected_choices':

                if (!message.reply_to_message_id || !message.choices) {
                    throw new Error("Selected choices message must have a reply_to_message_id and choices")
                }
                replyToAnswers.set(message.reply_to_message_id, {
                    value: message.choices!,
                    sender: message.sender,
                })

                break

            case 'single_choice':

                if (replyToAnswers.has(message.id)) {

                    const answer = replyToAnswers.get(message.id)!

                    message.selected_choices = answer.value
                    message.sender = answer.sender
                    
                    break
                }

                state.nextExpectedEncoding = 'selected_choices'
                state.replyToMessageId = message.id

                break

        }

    }

}