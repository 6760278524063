import { useState, useCallback, useEffect, useMemo } from 'react'

// https://github.com/oliviertassinari/react-swipeable-views/issues/676
// import SwipeableViews from 'react-swipeable-views'

import { Controller } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { Swiper as SwiperClass } from 'swiper';

import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

// import { SwipeablePage } from '@/components/SwipeablePage'
import { SettingsMenu } from '@/components/SettingsMenu'
import { HeaderNav } from '@/components/HeaderNav'
import { Chat } from '@/components/Conversation'
import { Typewritter } from '@/components/Typewritter'

import {
    SwipeableDrawer
} from '@mui/material'

import { useAuthContext } from '@/providers/AuthProvider'
import { useNavigate, useParams } from 'react-router-dom'
import { useThreadsStore } from '@/stores/threads/store'
import { useConversationStore } from '@/stores/conversation/store'
import { useShallow } from 'zustand/react/shallow'
import { MessagePayload } from '@/stores/conversation/types'
import { getCurrentLanguage } from '@/i18n'
import { Thread } from '@/stores/threads/types'

import { useTranslation } from 'react-i18next'
import { i18nFiles } from '@/i18n'
import { PurchaseDetails } from '@/components/PurchaseDetails';

export interface ChatScreenProps {
}

export function ChatScreen({
}: ChatScreenProps = {}) {

    const { threadId } = useParams()

    const { user, signOutUser } = useAuthContext()

    const [t] = useTranslation([i18nFiles.Components])

    const i18n = {
        newChatTitle: t('chatScreen.newChatTitle'),
    }
    const [swiper, setSwiper] = useState<SwiperClass | null>(null)

    const slideTo = useCallback((index: number) => swiper?.slideTo(index), [swiper])

    const navigate = useNavigate()

    const [isMenuOpen, toggleMenu] = useState(false)

    const onGoToMenu = useCallback(() => user ? toggleMenu(true) : navigate('/sign-in'), [user, toggleMenu])
    // const onGoToMenu = useCallback(() => slideTo(0), [slideTo])

    const {
        threads,
        subscribeThreads,
        unsubscribeThreads,
        createThread,
    } = useThreadsStore(
        useShallow(state => ({
            threads: state.threads,
            subscribeThreads: state.subscribeThreads,
            unsubscribeThreads: state.unsubscribeThreads,
            createThread: state.createThread,
        }))
    )

    const {
        thread,
        isTyping,
        messages,
        purchaseDetails,
        subscribeConversation,
        unsubscribeConversation,
        sendMessage,
    } = useConversationStore(
        useShallow(state => ({
            thread: state.thread,
            isTyping: state.isTyping,
            messages: state.messages,
            purchaseDetails: state.purchaseDetails,
            subscribeConversation: state.subscribeConversation,
            unsubscribeConversation: state.unsubscribeConversation,
            sendMessage: state.sendMessage,
        }))
    )

    const onSignOut = useCallback(async () => {
        await signOutUser()
        navigate('/')
        toggleMenu(false)

        unsubscribeConversation && unsubscribeConversation()
        unsubscribeThreads && unsubscribeThreads()
    }, [toggleMenu, unsubscribeConversation, unsubscribeThreads])

    useEffect(() => user ? subscribeThreads(user.uid) : undefined, [user])

    const language = getCurrentLanguage()

    const navigateToThread = useCallback(
        (nextThreadId?: string) => navigate(nextThreadId ? `/c/${nextThreadId}` : '/'),
        []
    )

    const onNewThread = useCallback(async () => {

        const newThreadId = await createThread(user!.uid, {
            language: language,
        })

        navigateToThread(newThreadId)
        await subscribeConversation(user!.uid, newThreadId)

    }, [user, language])

    useEffect(() => {

        if (threadId && user) {
            subscribeConversation(user!.uid, threadId)
        }

    }, [user])

    const onNewMessage = useCallback(
        async (payload: MessagePayload) => await sendMessage(user!.uid, payload),
        [user]
    )

    const onGoBack = useCallback(() => slideTo(0), [slideTo])

    const onSelectThread = useCallback((selectedThread: Thread) => {

        if (thread && thread.id === selectedThread.id) {
            toggleMenu(false)
            return
        }

        navigateToThread(selectedThread.id)
        subscribeConversation(user!.uid, selectedThread.id)
        toggleMenu(false)

    }, [user, thread, toggleMenu])

    const onNewPurchase = useCallback(() => {

        if (unsubscribeConversation) {
            unsubscribeConversation()
        }

        navigateToThread()

        onGoBack()

    }, [
        unsubscribeConversation,
        navigateToThread,
        onGoBack,
    ])

    const onStartNewThread = useCallback(async () => {

        if (unsubscribeConversation) {
            unsubscribeConversation()
        }

        await onNewThread()

        onGoBack()

    }, [
        unsubscribeConversation,
        onNewThread,
        onGoBack,
    ])

    const chatTitle = useMemo(() => thread?.title ? <Typewritter
        text={thread.title}
        speed={50}
    /> : i18n.newChatTitle,
        [thread?.title]
    )

    /*  */

    return (
        <Grid2
            container
            direction="column"
            justifyContent="flex-end"
            justifyItems="center"
            flexWrap="nowrap"
            sx={{
                height: '100%',
            }}
        >
            <Grid2 xs={12}>
                <HeaderNav
                    title={chatTitle}
                    onGoToMenu={onGoToMenu}
                    onStartNewThread={onStartNewThread}
                />
                <SwipeableDrawer
                    anchor="left"
                    open={isMenuOpen}
                    onClose={() => toggleMenu(false)}
                    onOpen={() => toggleMenu(true)}
                >
                    <SettingsMenu
                        threads={threads}
                        userAvatarImage={user?.photoURL}
                        userFirstName={user?.displayName}
                        onGoBack={onGoBack}
                        onSignOut={onSignOut}
                        onNewPurchase={onNewPurchase}
                        onSelectThread={onSelectThread}
                    />
                </SwipeableDrawer>
            </Grid2>

            <Grid2
                flexGrow={1}
                xs={12}
                overflow="auto"
                sx={{
                    height: '100%',
                    maxHeight: '100%',
                }}
            >
                <Swiper
                    direction="horizontal"
                    slidesPerView={1}
                    initialSlide={0}
                    style={{
                        height: '100%',
                    }}
                    modules={[Controller]}
                    onSwiper={setSwiper}
                >
                    <SwiperSlide
                        id="conversation-page"
                        style={{
                            height: '100%',
                        }}
                    >
                        <Chat
                            thread={thread}
                            messages={messages}
                            userDisplayName={user?.displayName?.split(' ')[0]}
                            disabledSender={isTyping}
                            onNewThread={onNewThread}
                            onNewMessage={onNewMessage}
                        />
                    </SwiperSlide>

                    <SwiperSlide
                        id="details-page"
                        style={{
                            height: '100%',
                        }}
                    >
                        <PurchaseDetails
                            purchaseDetails={purchaseDetails}
                        />
                    </SwiperSlide>

                </Swiper>

            </Grid2>

        </Grid2>
    )
}