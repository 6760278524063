
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

import { Thread as ThreadWrapper } from './Thread'
import { TypingMessage } from './TypingMessage'
import { ChoicesMessage } from './ChoicesMessage'
import { TextMessage } from './TextMessage'
import { MessageInput, MessageInputProps } from './MessageInput'

import { Thread } from '@/stores/threads/types'
import { Message } from '@/stores/conversation/types'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { i18nFiles, getDateFnsLocale } from '@/i18n'

import { formatDistanceToNow } from 'date-fns'

export interface ChatProps {
    thread?: Thread
    messages?: Message[]
    userDisplayName?: string
    disabledSender: boolean
    onNewThread: MessageInputProps['onNewThread']
    onNewMessage: MessageInputProps['onNewMessage']
}

export function Chat({
    thread,
    messages = [],
    userDisplayName,
    disabledSender = true,
    onNewThread,
    onNewMessage,
}: ChatProps) {

    const threadContainerRef = useRef<HTMLDivElement>(null)

    const [followScroll, setScrollFollower] = useState(true)
    const [animated, setAnimated] = useState(false)

    useEffect(() => {
        setAnimated(false)
        setScrollFollower(true)
    }, [thread])

    const dfnsLocale = getDateFnsLocale()

    const onMessageRendered = useCallback(() => {

        if (!threadContainerRef.current || !followScroll) {
            return
        }

        const scrollPosition = threadContainerRef.current.scrollHeight - threadContainerRef.current.clientHeight

        if (!animated) {
            threadContainerRef.current.scrollTop = scrollPosition
            return
        }

        threadContainerRef.current.scrollTo({
            top: scrollPosition,
            behavior: 'smooth',
        })

    }, [threadContainerRef.current, followScroll, animated])

    const onThreadScroll = useCallback(() => {

        if (!threadContainerRef.current) {
            return
        }

        const { scrollTop, scrollHeight, clientHeight } = threadContainerRef.current

        if (scrollTop + clientHeight * 1.1 >= scrollHeight) {
            setScrollFollower(true)
            return
        }

        setScrollFollower(false)

    }, [threadContainerRef.current])

    const [t] = useTranslation([i18nFiles.Components])

    const i18n = {
        chatYouLower: t('chat.youLower'),
        chatYou: t('chat.you'),
        chatFinnShopper: t('chat.finnShopper'),
        chatCustomerServiceTeam: t('chat.customerServiceTeam'),
        chatThankYouMessage: t('chat.thankYouMessage'),
    }

    const history = messages.map(message => {

        if (message.encoding === 'selected_choices') {
            return null
        }

        let senderFrom = message.sender as string

        switch (message.sender) {
            case 'user':
                senderFrom = userDisplayName ? `${userDisplayName} (${i18n.chatYouLower})` : i18n.chatYou
                break
            case 'finn':
                senderFrom = i18n.chatFinnShopper
                break
            case 'customer_service':
                senderFrom = i18n.chatCustomerServiceTeam
                break
        }

        const recordedAt = formatDistanceToNow(message.created_at, {
            locale: dfnsLocale,
            addSuffix: true,
        })

        switch (message.encoding) {

            case 'product_name':
                return null

            case 'typing':
                return (
                    <TypingMessage
                        key={message.id}
                        from={senderFrom}
                        recordedAt={recordedAt}
                    />
                )

            case 'text':
                return (
                    <TextMessage
                        key={message.id}
                        body={message.body ?? message.choices?.join(', ') ?? ''}
                        from={senderFrom}
                        recordedAt={recordedAt}
                    />
                )

            case 'single_choice':
                return (
                    <ChoicesMessage
                        key={message.id}
                        choices={message.choices}
                        i18nChoices={message.i18n_choices}
                        from={senderFrom}
                        recordedAt={recordedAt}
                        value={message.selected_choices}
                        onSelect={(selectedChoices) => {
                            setAnimated(true)
                            setScrollFollower(true)
                            return onNewMessage({
                                choices: selectedChoices,
                                encoding: 'selected_choices',
                                reply_to_message_id: message.id,
                            })
                        }}
                    />
                )

            default:
                return <div key={message.id}>{"Unhandled message econdig"}</div>
        }

    })

    if (thread?.title) {
        history.push(
            <TextMessage
                key={"chat-thank-you"}
                body={i18n.chatThankYouMessage}
                from={i18n.chatCustomerServiceTeam}
            />
        )
    }

    return <Grid2
        container
        direction="column"
        justifyItems="flex-end"
        sx={{
            height: '100%',
            minHeight: '100%',
        }}
    >
        <Grid2
            ref={threadContainerRef}
            flexGrow={1}
            onScroll={onThreadScroll}
            sx={{
                pt: 2,
                overflowY: 'auto',
                height: '0',
            }}
        >
            {thread && <ThreadWrapper
                threadId={thread.id}
                animated={animated}
                onMounted={onMessageRendered}
                onMessageRendered={onMessageRendered}
            >

                {history.length > 0 ? history : <TypingMessage
                    from={i18n.chatFinnShopper}
                />}

            </ThreadWrapper>}

        </Grid2>

        <Grid2 xs={12}>
            <MessageInput
                thread={thread}
                disabled={disabledSender || !!(thread && thread.title)}
                onNewThread={onNewThread}
                onNewMessage={(payload) => {
                    setAnimated(true)
                    setScrollFollower(true)
                    return onNewMessage(payload)
                }}
            />
        </Grid2>
    </Grid2>
}