// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
    apiKey: "AIzaSyClkhIBrd1RWfRoGcYfPy38pi8NlNMEDhA",
    authDomain: window.location.hostname === "localhost" ? "assistentedecompra-cea55.firebaseapp.com" : window.location.host,
    databaseURL: "https://assistentedecompra-cea55-default-rtdb.firebaseio.com",
    projectId: "assistentedecompra-cea55",
    storageBucket: "assistentedecompra-cea55.appspot.com",
    messagingSenderId: "883871544093",
    appId: "1:883871544093:web:c121374ae444b2369667f4",
    measurementId: "G-L1Y570GZNL"
}

const app = initializeApp(firebaseConfig)

export const analytics = getAnalytics(app)
export const auth = getAuth(app)
export const db = getDatabase(app)

auth.useDeviceLanguage()