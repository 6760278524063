import { useState, useEffect } from 'react'

import { LinearProgress } from '@mui/material'

export interface InfiniteLinearLoaderProps {
    step?: number
    interval?: number
}

export function InfiniteLinearLoader({
    step = 15,
    interval = 500,
}: InfiniteLinearLoaderProps = {}) {

    const [progress, setProgress] = useState(0)

    useEffect(() => {

        let progressStep = step
        let currentProgress = 0

        const timer = setInterval(() => {

            currentProgress += progressStep

            setProgress(currentProgress)

            progressStep /= 2

        }, interval)

        return () => {
            clearInterval(timer)
        }

    }, [])

    return (<LinearProgress value={progress} />)
}