import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemButton,
    Divider,
    ListSubheader,
    ListItemAvatar,
    Avatar,
    SwipeableDrawer,
} from '@mui/material'


import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

import LogoutIcon from '@mui/icons-material/Logout'
import MoreIcon from '@mui/icons-material/MoreHoriz'

import { useTranslation } from 'react-i18next'
import { i18nFiles, getDateFnsLocale } from '@/i18n'
import { Thread } from '@/stores/threads/types'

import { formatDistanceToNow } from 'date-fns'
import { useState, useMemo, useRef } from 'react'

export interface SettingsMenuProps {
    threads: Thread[]
    userAvatarImage?: string | null
    userFirstName?: string | null
    onGoBack?: () => void
    onSignOut?: () => void
    onNewPurchase?: () => void
    onSelectThread?: (thread: Thread) => void
}

export function SettingsMenu({
    threads = [],
    userAvatarImage,
    userFirstName,
    onSignOut,
    onSelectThread,
}: SettingsMenuProps) {

    const [t] = useTranslation([i18nFiles.Components])

    const i18n = {
        myAccountAndSettings: t('settingsMenu.myAccountAndSettings'),
        newPurchase: t('settingsMenu.newPurchase'),
        conversationList: t('settingsMenu.conversationList'),
        goBack: t('settingsMenu.goBack'),
        signOut: t('settingsMenu.signOut'),
        newChatTitle: t('chatScreen.newChatTitle'),
    }

    const dfnsLocale = getDateFnsLocale()


    const historyRef = useRef<HTMLDivElement>(null)

    const [openAccountSettings, setOpenAccountSettings] = useState(false)

    const handleOpenAccountSettings = () => {
        setOpenAccountSettings(!openAccountSettings)
    }

    // const handleNewPurchase = useCallback(() => {
    //     if (onNewPurchase) {
    //         onNewPurchase()
    //     }

    //     if (historyRef) {
    //         historyRef.current?.scrollTo({
    //             top: 0,
    //             behavior: 'smooth'
    //         })
    //     }
    // }, [onNewPurchase, historyRef])

    const conversationsList = (
        <Grid2
            flexGrow={1}
            ref={historyRef}
            sx={{
                overflowY: 'auto',
                height: '0',
            }}
        >
            <nav aria-label="secondary mailbox folders">
                <List
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {i18n.conversationList}
                        </ListSubheader>
                    }
                >
                    {threads.map(thread => (
                        <ListItem key={thread.id} disablePadding>
                            <ListItemButton
                                onClick={onSelectThread ? () => onSelectThread(thread) : undefined}
                            >
                                <ListItemText
                                    primary={thread.title ?? i18n.newChatTitle}
                                    secondary={
                                        formatDistanceToNow(
                                            thread.updated_at,
                                            {
                                                locale: dfnsLocale,
                                                addSuffix: true
                                            }
                                        )
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </nav>
        </Grid2>
    )

    const myAccountListItem = useMemo(() => (
        <ListItem disablePadding>
            <ListItemButton onClick={handleOpenAccountSettings}>
                <ListItemAvatar>
                    {userAvatarImage ?
                        <Avatar slotProps={{
                            img: {
                                referrerPolicy: 'no-referrer',
                            }
                        }} alt={userFirstName ?? undefined} src={userAvatarImage} />
                        : <Avatar>{userFirstName?.charAt(0).toUpperCase()}</Avatar>}
                </ListItemAvatar>
                <ListItemText primary={userFirstName} secondary={i18n.myAccountAndSettings} />
                <MoreIcon />
                {/* {openAccountSettings ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}
            </ListItemButton>
        </ListItem>
    ), [userFirstName, userAvatarImage, handleOpenAccountSettings])

    return (
        <Box sx={{
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            bgcolor: 'background.paper',
        }}
        >
            <Grid2
                container
                direction="column"
                justifyItems="flex-start"
                sx={{
                    height: '100%',
                    minHeight: '100%',
                }}
            >
                {threads.length > 0 && conversationsList}

                <Grid2>
                    {threads.length > 0 ? <Divider /> : undefined}
                    <nav aria-label="main mailbox folders">
                        <List>
                            {/* <ListItem disablePadding>
                                <ListItemButton onClick={onGoBack}>
                                    <ListItemIcon>
                                        <ArrowForward />
                                    </ListItemIcon>
                                    <ListItemText primary={i18n.goBack} />
                                </ListItemButton>
                            </ListItem> */}
                            {/* <ListItem disablePadding>
                                <ListItemButton onClick={handleNewPurchase}>
                                    <ListItemIcon>
                                        <ShoppingCartIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={i18n.newPurchase} />
                                </ListItemButton>
                            </ListItem> */}
                            {userFirstName && <>
                                {myAccountListItem}
                                <SwipeableDrawer
                                    anchor="bottom"
                                    open={openAccountSettings}
                                    onClose={() => setOpenAccountSettings(false)}
                                    onOpen={() => setOpenAccountSettings(true)}
                                    PaperProps={{
                                        sx: {
                                            display: 'flex',
                                            direction: 'column',
                                            height: '100%',
                                        }
                                    }}
                                >
                                    <List component="div" disablePadding sx={{
                                        flexGrow: 1
                                    }}>
                                        {myAccountListItem}

                                    </List>
                                    <List component="div" disablePadding>

                                        <ListItem disablePadding>
                                            <ListItemButton onClick={onSignOut} sx={{
                                                p: 2
                                            }}>
                                                <ListItemIcon>
                                                    <LogoutIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={i18n.signOut} />
                                            </ListItemButton>
                                        </ListItem>

                                    </List>
                                </SwipeableDrawer>
                                {/* <Collapse in={openAccountSettings} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>

                                        <ListItem disablePadding>
                                            <ListItemButton onClick={onSignOut}>
                                                <ListItemIcon>
                                                    <LogoutIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={i18n.signOut} />
                                            </ListItemButton>
                                        </ListItem>

                                    </List>
                                </Collapse> */}
                            </>}
                        </List>
                    </nav>
                </Grid2>
            </Grid2>
        </Box>
    )

}
