import {
    Card,
    CardHeader,
    CardContent,
    Typography,
} from '@mui/material'

export interface TextMessageProps {
    body: string
    from: string
    recordedAt?: string
}

export function TextMessage({
    body,
    from,
    recordedAt,
}: TextMessageProps) {

    return (
        <Card variant="outlined">
            <CardHeader
                title={from}
                titleTypographyProps={{
                    variant: 'subtitle1',
                }}
                subheader={recordedAt}
                subheaderTypographyProps={{
                    variant: 'caption',
                }}
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {body}
                </Typography>
            </CardContent>
        </Card>
    )
}