import React from 'react'
import ReactDOM from 'react-dom/client'

import '@/sentry'
import '@/i18n'
import 'swiper/css'

import App from '@/App.tsx'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
