import React, { Suspense } from 'react'

import useMediaQuery from '@mui/material/useMediaQuery'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import { getTheme, ThemeMode } from '@/theme'

import { PrivateRoute } from '@/components/PrivateRoute'

import { SignInScreen } from '@/screens/SignIn'
import { ChatScreen } from '@/screens/Chat'

import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'

import { AuthProvider, AuthStatus } from '@/providers/AuthProvider'
import { InfiniteLinearLoader } from './components/Loader'

const router = createBrowserRouter([
  {
    path: '/',
    element: <ChatScreen />,
  },
  {
    path: '/c/:threadId',
    element: <ChatScreen />,
  },
  {
    path: '/sign-in',
    element: <PrivateRoute
      targetStatus={[AuthStatus.SignedOut, AuthStatus.SigningIn]}
      redirectPath="/"
    >
      <SignInScreen />
    </PrivateRoute>,
  },
])

function App() {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () => getTheme(prefersDarkMode ? ThemeMode.Dark : ThemeMode.Light),
    [prefersDarkMode],
  )

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<InfiniteLinearLoader />}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </Suspense>
    </ThemeProvider>
  )
}

export default App
