
import {
    AppBar,
    Box,
    Container,
    IconButton,
    Toolbar,
    Typography,
} from '@mui/material'

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import MenuIcon from '@mui/icons-material/Menu'

import { useTranslation } from 'react-i18next'
import { i18nFiles } from '@/i18n'

export interface HeaderNavProps {
    title?: React.ReactNode
    onGoToMenu?: () => void
    onStartNewThread?: () => void
}

export function HeaderNav({
    title,
    onGoToMenu,
    onStartNewThread,
}: HeaderNavProps = {}) {

    const [t] = useTranslation([i18nFiles.Components])

    const i18n = {
        newPurchase: t('settingsMenu.newPurchase'),
    }

    // const avatar = userAvatarImage ? (
    //     <Avatar slotProps={{
    //         img: {
    //             referrerPolicy: 'no-referrer',
    //         }
    //     }} alt={userFirstName ?? undefined} src={userAvatarImage} />
    // ) : userFirstName ? (
    //     <Avatar>{userFirstName.charAt(0).toUpperCase()}</Avatar>
    // ) : (
    //     <Avatar>
    //         <LoginIcon />
    //     </Avatar>
    // )

    return (
        <AppBar
            position="static"
            elevation={0}
        >
            <Container
                maxWidth="xl"
            >
                <Toolbar
                    disableGutters
                >

                    <Box sx={{ flexGrow: 0 }}>
                        <IconButton
                            size="large"
                            color="inherit"
                            aria-label={i18n.newPurchase}
                            onClick={onGoToMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>

                    <Typography
                        variant="body1"
                        noWrap
                        component="div"
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            textAlign: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {title}
                    </Typography>

                    <Box sx={{ flexGrow: 0 }}>
                        {/* <IconButton
                            color="primary"
                            sx={{ p: '10px' }}
                            onClick={onClickUserAvatar}
                            aria-label="my account"
                        >
                            {avatar}
                        </IconButton> */}
                        <IconButton
                            size="large"
                            color="inherit"
                            aria-label={i18n.newPurchase}
                            onClick={onStartNewThread}
                        >
                            <ShoppingCartIcon />
                        </IconButton>
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    )
}