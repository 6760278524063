import {
    Box,
    Container,
    Button,
} from '@mui/material'

import GoogleIcon from '@mui/icons-material/Google'

import { useTranslation } from 'react-i18next'
import { i18nFiles } from '@/i18n'
import { useAuthContext } from '@/providers/AuthProvider'

export function SocialSignIn() {

    const {
        signInWithGoogle
    } = useAuthContext()

    const [t] = useTranslation([i18nFiles.Components])

    const i18n = {
        googleSignIn: t('socialSignIn.googleSignIn'),
    }

    return (
        <Container
            id="social-sign-in"
            sx={{
                py: 2,
            }}
        >
            <Box>
                <Button
                    fullWidth
                    size="large"
                    variant="outlined"
                    startIcon={<GoogleIcon />}
                    onClick={signInWithGoogle}
                >
                    {i18n.googleSignIn}
                </Button>
            </Box>
        </Container>
    )

}