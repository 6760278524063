import {
    Box,
    List,
    ListSubheader,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
} from '@mui/material'

import { PurchaseDetails as MessagePurchaseDetails } from '@/stores/conversation/types'

import ShoppingBagIcon from '@mui/icons-material/ShoppingBag'

import { useTranslation } from 'react-i18next'
import { i18nFiles } from '@/i18n'

export interface PurchaseDetailsProps {
    purchaseDetails: Partial<MessagePurchaseDetails>
}

export function PurchaseDetails({
    purchaseDetails,
}: PurchaseDetailsProps) {

    const [t] = useTranslation([i18nFiles.Components])

    const i18n = {
        subheader: t('purchaseDetails.subheader'),
        keepChatting: t('purchaseDetails.keepChatting'),
    }

    return (
        <Box sx={{
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            bgcolor: 'background.paper',
            overflowY: 'auto',
        }}
        >
            <List
                subheader={
                    <ListSubheader component="div" id="purchase-details-subheader">
                        {i18n.subheader}
                    </ListSubheader>
                }
            >

                {Object.keys(purchaseDetails).length === 0 && <ListItem>
                    <ListItemText secondary={i18n.keepChatting} />
                </ListItem>}

                {purchaseDetails.product_name && <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <ShoppingBagIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={purchaseDetails.product_name}
                        secondary={purchaseDetails.product_category}
                    />
                </ListItem>}

            </List>

        </Box>
    )

}