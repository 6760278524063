import React, { useEffect } from 'react'

import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { Container, Grow } from '@mui/material'

export interface ThreadProps {
    threadId: string
    children?: React.ReactNode
    animated?: boolean
    onMounted?: () => void
    onMessageRendered?: () => void
}

export function Thread({
    threadId,
    children,
    animated = false,
    onMounted,
    onMessageRendered,
}: ThreadProps) {

    const childrenCount = React.Children.count(children)

    useEffect(() => {

        if (childrenCount > 0 && !animated) {
            onMounted?.()
        }

    }, [childrenCount, animated])

    return (
        <Grid2
            id={threadId}
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="center"
            justifyItems="center"
            minHeight="calc(100% + var(--Grid-rowSpacing) / 2)"
            rowSpacing={2}
        >
            {React.Children.map(children, (child, i) => {

                if (!child) {
                    return
                }

                const message = <Grid2 key={i} xs={12}>
                    <Container>
                        {child}
                    </Container>
                </Grid2>

                if (i < childrenCount - 1 || !animated) {
                    return message
                }

                return (
                    <Grow
                        key={i}
                        in={true}
                        style={{ transformOrigin: 'bottom center' }}
                        timeout={500}
                        onEntered={onMessageRendered}
                    >{message}</Grow>
                )
            })?.filter(Boolean)}
        </Grid2>
    )

}