import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend'
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector'

import { Locale } from 'date-fns'
import {
  ptBR as dfnsPTBR,
  enUS as dfnsENUS,
  es as dfnsES,
} from 'date-fns/locale'

// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

type I18nOptions = HttpBackendOptions & DetectorOptions

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(HttpBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init<I18nOptions>({
    fallbackLng: {
      'en-US': ['en'],
      'en-GB': ['en'],
      'pt-BR': ['pt'],
      'default': ['en']
    },

    debug: false,

    returnObjects: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export enum i18nFiles {
  Components = 'components',
}

export type WellKnownLanguages = 'pt-br' | 'en-us' | 'es'

export function getCurrentLanguage(): WellKnownLanguages {
  switch (i18n.language.toLowerCase()) {
    case 'pt':
    case 'pt-br':
      return 'pt-br'
    case 'en':
    case 'en-us':
    case 'en-gb':
      return 'en-us'
    case 'es':
      return 'es'
    default:
      throw new Error(`Language not supported: ${i18n.language}`);
  }
}

export function getDateFnsLocale(): Locale {
  switch (getCurrentLanguage()) {
    case 'pt-br':
      return dfnsPTBR
    case 'en-us':
      return dfnsENUS
    case 'es':
      return dfnsES
  }
}

export default i18n