import {
    Stack,
    Button,
} from '@mui/material'

import { styled } from '@mui/material/styles'


import { useTranslation } from 'react-i18next'
import { i18nFiles } from '@/i18n'

import {
    TextMessage
} from './TextMessage'


const ChoiceButton = styled(Button)({
    textTransform: 'none',
})
export interface ChoicesMessageProps {
    singleChoice?: boolean
    choices?: string[],
    i18nChoices?: string[],
    from: string
    recordedAt: string
    value?: string[] | null,
    onSelect: (choice: string[]) => void
}


export function ChoicesMessage({
    // singleChoice = true,
    choices,
    i18nChoices,
    from,
    recordedAt,
    value,
    onSelect,
}: ChoicesMessageProps) {

    const [t] = useTranslation([i18nFiles.Components])

    const parsedChoices = [
        ...choices ?? [],
        ...i18nChoices ? i18nChoices.map(choice => t(choice)) : [],
    ]

    if (!value) {
        return <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            justifyContent="flex-end"
            useFlexGap
            flexWrap="wrap"
        >
            {parsedChoices.map((choice, i) => {

                return (
                    <ChoiceButton
                        key={i}
                        variant="outlined"
                        onClick={() => onSelect([choice])}
                        type="button"
                    >{choice}</ChoiceButton>
                )

            })}
        </Stack>
    }

    return <TextMessage
        body={value.join(', ')}
        from={from}
        recordedAt={recordedAt}
    />

}