import {
    useCallback,
    useState
} from 'react'

import {
    Paper,
    IconButton,
    InputBase,
    Container,
    Button,
} from '@mui/material'

import SendIcon from '@mui/icons-material/Send'
import MessageIcon from '@mui/icons-material/Message'

import { useTranslation } from 'react-i18next'
import { i18nFiles } from '@/i18n'

import { Thread } from '@/stores/threads/types'
import { MessagePayload } from '@/stores/conversation/types'

export interface MessageInputProps {
    thread?: Thread
    placeholder?: string
    disabled: boolean
    onNewThread: () => Promise<void>
    onNewMessage: (payload: MessagePayload) => Promise<string>
}

export function MessageInput({
    thread,
    placeholder,
    disabled = true,
    onNewThread,
    onNewMessage,
}: MessageInputProps) {

    const [message, setMessage] = useState('')

    const [t] = useTranslation([i18nFiles.Components])

    const i18n = {
        inputPlaceholder: placeholder ?? t('messageInput.input.placeholder'),
        newChat: placeholder ?? t('messageInput.newChat'),
    }

    const handleSubmit = useCallback(async () => {

        if (!thread) {
            throw new Error('Thread is required')
        }

        await onNewMessage({
            body: message,
            encoding: 'text',
        })

        setMessage('')

    }, [thread, message])

    return (
        <Container
            id="message-input"
            sx={{
                pb: 2,
            }}
        >
            {thread ? (
                <>

                    <Paper
                        component="form"
                        variant="outlined"
                        elevation={0}
                        sx={{
                            mt: 1,
                            p: '6px 4px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                        }}
                        onSubmit={(e) => {
                            e.preventDefault()
                            handleSubmit()
                        }}
                    >
                        <InputBase
                            multiline
                            disabled={disabled}
                            maxRows={4}
                            sx={{ ml: 1, flex: 1 }}
                            placeholder={i18n.inputPlaceholder}
                            inputProps={{ 'aria-label': i18n.inputPlaceholder }}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <IconButton
                            type="submit"
                            disabled={disabled}
                            sx={{ p: '6px' }}
                            aria-label="search"
                        >
                            <SendIcon />
                        </IconButton>
                    </Paper>
                </>
            ) : (
                <>
                    <Button
                        type="button"
                        variant="outlined"
                        startIcon={<MessageIcon />}
                        fullWidth
                        sx={{
                            mt: 1,
                            p: '8px 4px',
                        }}
                        onClick={onNewThread}
                    >{i18n.newChat}</Button>
                </>
            )}
        </Container>
    )
}
