import { deepmerge } from '@mui/utils'
import { createTheme, Theme } from '@mui/material/styles'

export enum ThemeMode {
    Light = 'light',
    Dark = 'dark',
}

export const getTheme = (themeMode: ThemeMode): Theme => {
    console.log(themeMode)
    return createTheme(deepmerge({}, {}))
}