import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import { db } from '@/firebase'
import {
    ref,
    push,
    onChildAdded,
    onChildChanged,
    serverTimestamp,
    DataSnapshot
} from 'firebase/database'

import { ThreadsStore, ThreadsState, Thread } from './types'

const initialState: ThreadsState = {
    threads: [],
}

export const useThreadsStore = create(
    immer<ThreadsStore>((set, get) => ({

        ...initialState,

        subscribeThreads: (userId) => {

            let unsubscribeThreads = get().unsubscribeThreads

            if (unsubscribeThreads) {
                unsubscribeThreads()
            }

            const onChildWritten = (snapshot: DataSnapshot) => {

                const thread: Thread | null = snapshot.val()

                if (!thread) {
                    return
                }

                set(state => {
                    state.threads = [
                        ...state.threads.filter(t => t.id !== snapshot.key!),
                        {
                            ...thread,
                            id: snapshot.key!,
                        },
                    ].sort(
                        (a, b) => b.updated_at - a.updated_at
                    )
                })
            }

            const onError = (error: Error) => {

                console.error(error)

                set(state => {
                    delete state.unsubscribeThreads
                })

            }

            const threadsRef = ref(db, `threads/${userId}`)

            const unsubChildAdded = onChildAdded(threadsRef, onChildWritten, onError)
            const unsubChildChanged = onChildChanged(threadsRef, onChildWritten, onError)

            unsubscribeThreads = () => {
                unsubChildAdded()
                unsubChildChanged()

                set({...initialState})
            }

            set(state => { state.unsubscribeThreads = unsubscribeThreads })

            return unsubscribeThreads

        },

        createThread: async (userId, thread) => {

            const threadRef = await push(ref(db, `threads/${userId}`), {
                ...thread,
                updated_at: serverTimestamp(),
            })

            return threadRef.key!

        },

    }))
)