import {
    Card,
    CardHeader,
    CardContent,
    Typography,
} from '@mui/material'

import { TypeAnimation } from 'react-type-animation'

import { useTranslation } from 'react-i18next'
import { i18nFiles } from '@/i18n'

export interface TypingMessageProps {
    from: string
    recordedAt?: string
}

export function TypingMessage({
    from,
    recordedAt,
}: TypingMessageProps) {

    const [t] = useTranslation([i18nFiles.Components])

    const i18n = {
        chatTyping: t('chat.typing') as unknown as Array<string | number>,
    }

    return (
        <Card variant="outlined">
            <CardHeader
                title={from}
                titleTypographyProps={{
                    variant: 'subtitle1',
                }}
                subheader={recordedAt}
                subheaderTypographyProps={{
                    variant: 'caption',
                }}
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    <TypeAnimation
                        preRenderFirstString={true}
                        sequence={i18n.chatTyping}
                        cursor={false}
                        repeat={Infinity}
                    />
                </Typography>
            </CardContent>
        </Card>
    )
}