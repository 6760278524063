/** @jsxImportSource @emotion/react */

import { css, keyframes } from '@emotion/react'

const typing = keyframes`
    from { width: 0 }
    to { width: 100% }
`

export interface TypewritterProps {
    text: string
    speed?: number
}

export function Typewritter({
    text,
    speed = 50,
}: TypewritterProps) {

    return (
        <div
            css={css`
                display: inline-block
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
                animation:
                    ${typing} ${speed * text.length}ms steps(${text.length}, end);
                `}
        >
            {text}
        </div>
    )

}